import { graphql } from "gatsby"
import React from "react"

import DefaultLayout from "~/layouts/default"
import Section from "~/components/section/Section"
import SEO from "~/components/SEO"

export default ({ data }) => {
  const { title, sections, description, cover } = data.contentfulArticle

  return (
    <DefaultLayout backButton={true}>
      <SEO
        description={description}
        title={title}
        lang="en"
        thumbnail={cover}
      />
      {sections.map((section, index) => (
        <Section key={index} section={section} />
      ))}
    </DefaultLayout>
  )
}

export const query = graphql`
  query ArticleBySlug($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      description
      cover {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid
        }
        fixed {
          src
          height
          width
        }
      }
      sections {
        __typename
        ... on ContentfulSectionOneColumn {
          theme
          size
          content {
            json
          }
        }
        ... on ContentfulSectionTwoColumn {
          theme
          size
          left {
            json
          }
          right {
            title
            description
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
        ... on ContentfulSectionThreeColumn {
          theme
          size
          left {
            json
          }
          middle {
            json
          }
          right {
            json
          }
        }
        ... on ContentfulGallery {
          size
          theme
          media {
            title
            description
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
